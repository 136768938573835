'use client'

import * as React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider } from 'next-themes'
import { Flowbite } from 'flowbite-react'
import { SnackbarProvider } from 'notistack'
import StoreProvider from './StoreProvider'
import FlowbiteTheme from './theme/Flowbite'
import type {
  AuthProvider,
  Coupon,
  Gender,
  ImageGuideType,
  MembershipPeriod,
  MembershipType,
  OrientationType,
  Price,
  PurchaseType,
  Size,
  SizeType,
  Style,
  StyleType,
  User,
  VerifyType,
} from '@/types/entities'

interface Props {
  children: React.ReactNode
  authProviders: AuthProvider[] | null
  coupons: Coupon[] | null
  currentUser: User | null
  genders: Gender[] | null
  imageGuideTypes: ImageGuideType[] | null
  membershipPeriods: MembershipPeriod[] | null
  membershipTypes: MembershipType[] | null
  orientationTypes: OrientationType[] | null
  prices: Price[] | null
  purchaseTypes: PurchaseType[] | null
  sizes: Size[] | null
  sizeTypes: SizeType[] | null
  styles: Style[] | null
  styleTypes: StyleType[] | null
  token: string | null
  verifyTypes: VerifyType[] | null
  version: string
}

const flowbiteTheme = { theme: FlowbiteTheme }

const snackbarRootClasses = [
  '[&>.notistack-MuiContent-default]:bg-neutral-700',
  '[&>.notistack-MuiContent-info]:bg-info',
  '[&>.notistack-MuiContent-success]:bg-success',
  '[&>.notistack-MuiContent-warning]:bg-warning',
  '[&>.notistack-MuiContent-error]:bg-danger',
  'dark:[&>.notistack-MuiContent-default]:bg-neutral-700',
  'dark:[&>.notistack-MuiContent-info]:bg-info-dark',
  'dark:[&>.notistack-MuiContent-success]:bg-success-dark',
  'dark:[&>.notistack-MuiContent-warning]:bg-warning-dark',
  'dark:[&>.notistack-MuiContent-error]:bg-danger-dark',
]

const snackbarStyles = {
  root: snackbarRootClasses.join(' '),
}

export default function App({
  children,
  authProviders,
  coupons,
  currentUser,
  genders,
  imageGuideTypes,
  membershipPeriods,
  membershipTypes,
  orientationTypes,
  prices,
  purchaseTypes,
  sizes,
  sizeTypes,
  styles,
  styleTypes,
  token,
  verifyTypes,
  version,
}: Props): React.ReactElement {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
          },
        },
      }),
  )

  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider
        authProviders={authProviders}
        coupons={coupons}
        currentUser={currentUser}
        genders={genders}
        imageGuideTypes={imageGuideTypes}
        membershipPeriods={membershipTypes}
        membershipTypes={membershipPeriods}
        orientationTypes={orientationTypes}
        prices={prices}
        purchaseTypes={purchaseTypes}
        sizes={sizes}
        sizeTypes={sizeTypes}
        styles={styles}
        styleTypes={styleTypes}
        token={token}
        verifyTypes={verifyTypes}
        version={version}
      >
        <ThemeProvider attribute="class">
          <SnackbarProvider classes={snackbarStyles}>
            <Flowbite theme={flowbiteTheme}>{children}</Flowbite>
          </SnackbarProvider>
        </ThemeProvider>
      </StoreProvider>
    </QueryClientProvider>
  )
}
