'use client'

import * as React from 'react'
import '@/app/style/globals.scss'
import { Oooh_Baby } from 'next/font/google'
import { faTools } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ooohBaby = Oooh_Baby({
  weight: ['400'],
  subsets: ['latin'],
  display: 'swap',
})

export default function Maintenance(): React.ReactElement {
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center bg-neutral-200">
      <div className="text-primary-text text-xl">
        <span className={`${ooohBaby.className} logo-outline text-6xl tracking-wider`}>PicTrix</span>
        <span className={`${ooohBaby.className} logo-outline ml-0.5 text-3xl`}>.ai</span>
      </div>
      <FontAwesomeIcon icon={faTools} className="mt-16 size-32" />
      <div className="text-primary-text mt-8 text-xl">Currently undergoing maintenance</div>
      <div className="text-primary-text/60 mt-4 text-sm">Check back with us soon!</div>
    </div>
  )
}
