'use client'

import * as React from 'react'
import { Provider } from 'react-redux'
import { makeStore, type AppStore } from '@/lib/store'
import { setAuthProviders } from '@/lib/features/authProviders/authProvidersSlice'
import { setCoupons } from '@/lib/features/coupons/couponsSlice'
import { setCurrentUser } from '@/lib/features/currentUser/currentUserSlice'
import { setGenders } from '@/lib/features/genders/gendersSlice'
import { setImageGuideTypes } from '@/lib/features/imageGuideTypes/imageGuideTypesSlice'
import { setMembershipPeriods } from '@/lib/features/membershipPeriods/membershipPeriodsSlice'
import { setMembershipTypes } from '@/lib/features/membershipTypes/membershipTypesSlice'
import { setOrientationTypes } from '@/lib/features/orientationTypes/orientationTypesSlice'
import { setPrices } from '@/lib/features/prices/pricesSlice'
import { setPurchaseTypes } from '@/lib/features/purchaseTypes/purchaseTypesSlice'
import { setSizes } from '@/lib/features/sizes/sizesSlice'
import { setSizeTypes } from '@/lib/features/sizeTypes/sizeTypesSlice'
import { setStyles } from '@/lib/features/styles/stylesSlice'
import { setStyleTypes } from '@/lib/features/styleTypes/styleTypesSlice'
import { setToken } from '@/lib/features/token/tokenSlice'
import { setVerifyTypes } from '@/lib/features/verifyTypes/verifyTypesSlice'
import { setVersion } from '@/lib/features/version/versionSlice'
import type {
  AuthProvider,
  Coupon,
  Gender,
  Image,
  ImageGuideType,
  MembershipPeriod,
  MembershipType,
  OrientationType,
  Price,
  PurchaseType,
  Size,
  SizeType,
  Style,
  StyleType,
  User,
  VerifyType,
} from '@/types/entities'
import {
  setGeneratorSettingsGeneratingStatus,
  setGeneratorSettingsGenerationStartedAt,
  setGeneratorSettingsGpuProviderId,
  setGeneratorSettingsIsLoaded,
  setGeneratorSettingsIsPortrait,
  setGeneratorSettingsLastGeneratedImage,
  setGeneratorSettingsPrompt,
  setGeneratorSettingsSeed,
  setGeneratorSettingsShowImageGuide,
  setGeneratorSettingsSize,
  setGeneratorSettingsStyle,
  setGeneratorSettingsStyleType,
} from '@/lib/features/generatorSettings/generatorSettingsSlice'
import type { EnumImageGenerationStatus } from '../../../../../lib/database/lib/generated/enums'

interface Props {
  children: React.ReactNode
  authProviders: AuthProvider[] | null
  coupons: Coupon[] | null
  currentUser: User | null
  genders: Gender[] | null
  imageGuideTypes: ImageGuideType[] | null
  membershipPeriods: MembershipPeriod[] | null
  membershipTypes: MembershipType[] | null
  orientationTypes: OrientationType[] | null
  prices: Price[] | null
  purchaseTypes: PurchaseType[] | null
  sizes: Size[] | null
  sizeTypes: SizeType[] | null
  styles: Style[] | null
  styleTypes: StyleType[] | null
  token: string | null
  verifyTypes: VerifyType[] | null
  version: string
}

export default function StoreProvider({
  children,
  authProviders,
  coupons,
  currentUser,
  genders,
  imageGuideTypes,
  membershipPeriods,
  membershipTypes,
  orientationTypes,
  prices,
  purchaseTypes,
  sizes,
  sizeTypes,
  styles,
  styleTypes,
  token,
  verifyTypes,
  version,
}: Props): React.ReactElement {
  const storeRef = React.useRef<AppStore | null>(null)

  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current = makeStore()

    // Set initial state from props (provided by server-side)
    storeRef.current.dispatch(setAuthProviders(authProviders ?? null))
    storeRef.current.dispatch(setCoupons(coupons ?? null))
    storeRef.current.dispatch(setCurrentUser(currentUser ?? null))
    storeRef.current.dispatch(setGenders(genders ?? null))
    storeRef.current.dispatch(setImageGuideTypes(imageGuideTypes ?? null))
    storeRef.current.dispatch(setMembershipPeriods(membershipPeriods ?? null))
    storeRef.current.dispatch(setMembershipTypes(membershipTypes ?? null))
    storeRef.current.dispatch(setOrientationTypes(orientationTypes ?? null))
    storeRef.current.dispatch(setPrices(prices ?? null))
    storeRef.current.dispatch(setPurchaseTypes(purchaseTypes ?? null))
    storeRef.current.dispatch(setSizes(sizes ?? null))
    storeRef.current.dispatch(setSizeTypes(sizeTypes ?? null))
    storeRef.current.dispatch(setStyles(styles ?? null))
    storeRef.current.dispatch(setStyleTypes(styleTypes ?? null))
    storeRef.current.dispatch(setToken(token ?? null))
    storeRef.current.dispatch(setVerifyTypes(verifyTypes ?? null))
    storeRef.current.dispatch(setVersion(version ?? null))
  }

  React.useEffect(() => {
    if (!storeRef.current) {
      return
    }

    const generatingStatus = localStorage.getItem('generatorSettingsGeneratingStatus')
    const isPortrait = localStorage.getItem('generatorSettingsIsPortrait')
    const seed = localStorage.getItem('generatorSettingsSeed')
    const showImageGuide = localStorage.getItem('generatorSettingsShowImageGuide')
    const size = localStorage.getItem('generatorSettingsSize')
    const style = localStorage.getItem('generatorSettingsStyle')
    const styleType = localStorage.getItem('generatorSettingsStyleType')
    const lastGeneratedImage = localStorage.getItem('generatorSettingsLastGeneratedImage')
    const generationStartedAt = localStorage.getItem('generatorSettingsGenerationStartedAt')
    const gpuProviderId = localStorage.getItem('generatorSettingsGpuProviderId')

    storeRef.current.dispatch(setGeneratorSettingsGeneratingStatus((generatingStatus as keyof typeof EnumImageGenerationStatus) ?? null))
    storeRef.current.dispatch(setGeneratorSettingsIsPortrait(isPortrait === 'true'))
    storeRef.current.dispatch(setGeneratorSettingsPrompt(localStorage.getItem('generatorSettingsPrompt')))
    storeRef.current.dispatch(setGeneratorSettingsSeed(seed))
    storeRef.current.dispatch(setGeneratorSettingsShowImageGuide(showImageGuide === 'true'))
    storeRef.current.dispatch(setGeneratorSettingsSize(size ? (JSON.parse(size) as Size) : null))
    storeRef.current.dispatch(setGeneratorSettingsStyle(style ? (JSON.parse(style) as Style) : null))
    storeRef.current.dispatch(setGeneratorSettingsStyleType(styleType ? (JSON.parse(styleType) as StyleType) : null))
    storeRef.current.dispatch(setGeneratorSettingsLastGeneratedImage(lastGeneratedImage ? (JSON.parse(lastGeneratedImage) as Image) : null))
    storeRef.current.dispatch(setGeneratorSettingsGenerationStartedAt(generationStartedAt))
    storeRef.current.dispatch(setGeneratorSettingsGpuProviderId(gpuProviderId))
    storeRef.current.dispatch(setGeneratorSettingsIsLoaded(true))
  }, [])

  return <Provider store={storeRef.current}>{children}</Provider>
}
